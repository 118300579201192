import Axios from "axios";
import config from "../config";

// let devAuthKey = ''


const AuthService = {
	getAuthToken: async (authCode) => {
		try {
			const res = await Axios({
				headers: {
					"x-auth-code": authCode,
				},
				method: 'post',
				url: `${config.REACT_APP_PRODWS_AFREESPACE_URL}/api/login/code`
			});
			return res;
		} catch (err) {
			console.error(err);
			throw (err.response.data.error)
		}
	},

	authenticationKey: function () {
		return localStorage.getItem("spaceio-key") ? localStorage.getItem("spaceio-key") : null
	},

	login: async (username, password) => {
		try {
			const response = await Axios.post(`${config.REACT_APP_PRODWS_AFREESPACE_URL}/api/login`, {
				"username": username,
				"password": password
			})
			const key = (response.headers["x-auth-key"])
			window.localStorage.setItem("spaceio-key", (key))
			return true
		} catch (err) {
			throw (err.response.data.error)
		}
	},

	logout: () => {
		window.localStorage.removeItem("spaceio-key");
	},

	searchAPIModified: async (blessIDAndMacID, type) => {
		let myHeaders = new Headers();
		// myHeaders.append("X-REFERRER", "htts://account.dev.afreespace.com");
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append("x-auth-key", window.localStorage.getItem('spaceio-key'));
		// myHeaders.append("x-auth-key", "eyJhbGciOiJSUzI1NiJ9.eyJ1dHAiOiJVIiwidHRwIjoiQUNDIiwic3ViIjoiMjIxMzgiLCJhdWQiOiJodHRwczovL2FkbWluLXRlbXAuYWZyZWVzcGFjZS5jb20vP3JlZGlyZWN0X3RvPWh0dHBzJTNBJTJGJTJGYWRtaW4tdGVtcC5hZnJlZXNwYWNlLmNvbSUyRnNwYWNlLWxpc3QuaHRtbCUyMzM2NjUiLCJleHAiOjE3MjEwMDczMzEsImFpZCI6MTIsImlhdCI6MTcyMDM1OTMzMX0.Xj0B1ij2mjMpFbP-yvtFLr-DQVGMmbicap3TAdcJTGdkqVS7mE-DjjCdgUyivOedUBZOdGzLSxXWsugENelWfNn-pKXscXEfqmifvmlXztE3hKH--Bu97i8OfhiZ7KHd-THomYwKEUFl67XPTmRO1MZF2pQI6fZiKrWBfckG-EPSHe9iDnLxgu9cfLXft-hYi_aCAqxuH2-ULmCSoliU1zG0so49pupfWx46JhzHgD5zega1BZXH3XXBV8YQU46uIP89kVRqAAnkvm6cWCsbMJq9iGwHcqgSl8ZHJFy2xIhJsRPFoaQ3_xjct5rTQllu9HsJpX0KMJLte10DtiksxA");

		let raw = JSON.stringify(blessIDAndMacID);

		let requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: raw,
			redirect: 'follow'
		};

		const response = await fetch(`${config.REACT_APP_PRODWS_AFREESPACE_URL}/api/admin/devices/search?type=${type}`, requestOptions)
		const result = await response.json();
		return result;

	},

	summaryApi: async (macAddress) => {
		let myHeaders = new Headers();
		myHeaders.append("Authorization", "Bearer " +window.localStorage.getItem('spaceio-key'));
		// myHeaders.append("x-auth-key", "eyJhbGciOiJSUzI1NiJ9.eyJ1dHAiOiJVIiwidHRwIjoiQUNDIiwic3ViIjoiMjIxMzgiLCJhdWQiOiJodHRwczovL2FkbWluLXRlbXAuYWZyZWVzcGFjZS5jb20vP3JlZGlyZWN0X3RvPWh0dHBzJTNBJTJGJTJGYWRtaW4tdGVtcC5hZnJlZXNwYWNlLmNvbSUyRnNwYWNlLWxpc3QuaHRtbCUyMzM2NjUiLCJleHAiOjE3MjEwMDczMzEsImFpZCI6MTIsImlhdCI6MTcyMDM1OTMzMX0.Xj0B1ij2mjMpFbP-yvtFLr-DQVGMmbicap3TAdcJTGdkqVS7mE-DjjCdgUyivOedUBZOdGzLSxXWsugENelWfNn-pKXscXEfqmifvmlXztE3hKH--Bu97i8OfhiZ7KHd-THomYwKEUFl67XPTmRO1MZF2pQI6fZiKrWBfckG-EPSHe9iDnLxgu9cfLXft-hYi_aCAqxuH2-ULmCSoliU1zG0so49pupfWx46JhzHgD5zega1BZXH3XXBV8YQU46uIP89kVRqAAnkvm6cWCsbMJq9iGwHcqgSl8ZHJFy2xIhJsRPFoaQ3_xjct5rTQllu9HsJpX0KMJLte10DtiksxA");

		let requestOptions = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow'
		};

		const response = await fetch(`${config.REACT_APP_NEW_ELECTRIC_LAMBDA_URL}summary?deviceId=${macAddress}`, requestOptions)
		const result = await response.json();
		return result;
	},
	productType: async (productId) => {
		let myHeaders = new Headers();
		myHeaders.append("Authorization", "Bearer " +window.localStorage.getItem('spaceio-key'));
		// myHeaders.append("x-auth-key", "eyJhbGciOiJSUzI1NiJ9.eyJ1dHAiOiJVIiwidHRwIjoiQUNDIiwic3ViIjoiMjIxMzgiLCJhdWQiOiJodHRwczovL2FkbWluLXRlbXAuYWZyZWVzcGFjZS5jb20vP3JlZGlyZWN0X3RvPWh0dHBzJTNBJTJGJTJGYWRtaW4tdGVtcC5hZnJlZXNwYWNlLmNvbSUyRnNwYWNlLWxpc3QuaHRtbCUyMzM2NjUiLCJleHAiOjE3MjEwMDczMzEsImFpZCI6MTIsImlhdCI6MTcyMDM1OTMzMX0.Xj0B1ij2mjMpFbP-yvtFLr-DQVGMmbicap3TAdcJTGdkqVS7mE-DjjCdgUyivOedUBZOdGzLSxXWsugENelWfNn-pKXscXEfqmifvmlXztE3hKH--Bu97i8OfhiZ7KHd-THomYwKEUFl67XPTmRO1MZF2pQI6fZiKrWBfckG-EPSHe9iDnLxgu9cfLXft-hYi_aCAqxuH2-ULmCSoliU1zG0so49pupfWx46JhzHgD5zega1BZXH3XXBV8YQU46uIP89kVRqAAnkvm6cWCsbMJq9iGwHcqgSl8ZHJFy2xIhJsRPFoaQ3_xjct5rTQllu9HsJpX0KMJLte10DtiksxA");

		let requestOptions = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow'
		};

		const response = await fetch(`${config.REACT_APP_NEW_ELECTRIC_LAMBDA_URL}productmodel?productId=${productId}`, requestOptions)
		const result = await response.json();
		return result;
	},
	modelName: async (deviceId) => {
		let myHeaders = new Headers();
		myHeaders.append("Authorization", "Bearer " +window.localStorage.getItem('spaceio-key'));
		// myHeaders.append("x-auth-key", "eyJhbGciOiJSUzI1NiJ9.eyJ1dHAiOiJVIiwidHRwIjoiQUNDIiwic3ViIjoiMjIxMzgiLCJhdWQiOiJodHRwczovL2FkbWluLXRlbXAuYWZyZWVzcGFjZS5jb20vP3JlZGlyZWN0X3RvPWh0dHBzJTNBJTJGJTJGYWRtaW4tdGVtcC5hZnJlZXNwYWNlLmNvbSUyRnNwYWNlLWxpc3QuaHRtbCUyMzM2NjUiLCJleHAiOjE3MjEwMDczMzEsImFpZCI6MTIsImlhdCI6MTcyMDM1OTMzMX0.Xj0B1ij2mjMpFbP-yvtFLr-DQVGMmbicap3TAdcJTGdkqVS7mE-DjjCdgUyivOedUBZOdGzLSxXWsugENelWfNn-pKXscXEfqmifvmlXztE3hKH--Bu97i8OfhiZ7KHd-THomYwKEUFl67XPTmRO1MZF2pQI6fZiKrWBfckG-EPSHe9iDnLxgu9cfLXft-hYi_aCAqxuH2-ULmCSoliU1zG0so49pupfWx46JhzHgD5zega1BZXH3XXBV8YQU46uIP89kVRqAAnkvm6cWCsbMJq9iGwHcqgSl8ZHJFy2xIhJsRPFoaQ3_xjct5rTQllu9HsJpX0KMJLte10DtiksxA");

		let requestOptions = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow'
		};

		const response = await fetch(`${config.REACT_APP_NEW_ELECTRIC_LAMBDA_URL}productdevicegroup?deviceGroupId=${deviceId}`, requestOptions)
		const result = await response.json();
		return result;
	},

	deblessedAPI: async (loginKey, macAddress, deviceId) => {
		let myHeaders = new Headers();
		myHeaders.append("x-auth-key", window.localStorage.getItem('spaceio-key'));
		// myHeaders.append("x-auth-key", "eyJhbGciOiJSUzI1NiJ9.eyJ1dHAiOiJVIiwidHRwIjoiQUNDIiwic3ViIjoiMjIxMzgiLCJhdWQiOiJodHRwczovL2FkbWluLXRlbXAuYWZyZWVzcGFjZS5jb20iLCJmZWEiOiIxLDM1LDgsMTc3LDI3LDU5LDI4LDI5LDMwLDMxLDE1OSwzMiwxNjAiLCJleHAiOjE3MDQyNDczNjMsImFpZCI6MTIsImlhdCI6MTcwMzU5OTM2M30.PcEU1ikCmtqj0DRcpwDOOKAtqWOy_kVCkp7SOiU-WGMHGxrN5J4Ws0_sBlhKq7fsNxzg-DX4DVoYIQ_OUt5B-WZ4umEqncp2G2zhZgwP7_izbvOlmS3obV9bPwouuWK93oa-0c8tR7GFCzkVfeetcuPZJbar7Jzmod_w5P2Ca7TytyW9sHKNr8i_xhm6dRTT9L2QqbQtUbQNcLXcT1q1BW6BUrkbEyxU06G6K_VQDhzmedpQDqQs24lhZavSX1PmryMBtoIfhzCmHlSD9kreySgJeCDaQJUqparC5i4kTagYq6dumzB66CGT72ZtGK0IIyeXhDV6jT-YemWZ3z96Qg");
		myHeaders.append("content-type", "application/json");

		let raw = JSON.stringify({

			"XElectricImpKey": `${loginKey}`,
			"deviceData": { "data": [{ "type": "device", "id": `${macAddress}` }] },
		});

		let requestOptions = {
			method: 'DELETE',
			headers: myHeaders,
			body: raw,
			redirect: 'follow'
		};
		const response = await fetch(`${config.REACT_APP_NEW_ELECTRIC_LAMBDA_URL}debless?deviceGroupId=${deviceId}`, requestOptions)
		const result = await response.json();
		return result;
	},
	factoryEvent: async (macAddress, blessId) => {
		console.log("in factory event", macAddress, blessId)
		const myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append("Authorization", "Bearer " +window.localStorage.getItem('spaceio-key'));
		// myHeaders.append("Authorization", "Bearer eyJhbGciOiJSUzI1NiJ9.eyJ1dHAiOiJVIiwidHRwIjoiQUNDIiwic3ViIjoiMzEwOTc1IiwiYXVkIjoiaHR0cHM6Ly9iZXRhLWFkbWluLmFmcmVlc3BhY2UuY29tLz9yZWRpcmVjdF90bz1odHRwcyUzQSUyRiUyRmJldGEtYWRtaW4uYWZyZWVzcGFjZS5jb20lMkY5NDQlMkZjb250cm9scy5odG1sIiwiZXhwIjoxNzIxMzQwODE1LCJhaWQiOjEyLCJpYXQiOjE3MjA2OTI4MTV9.OisG-KZKJjP0WKx-QUYCtBFvE2H0I3oviavX_YZsYzjEKKoXINQaNlReAUv9HcPQmXjpcqgP46-aciMeZKux_f1HRSvJTHUvjLUIvMROg_fG1CVBDOBScvnB9RShjUuA3fmqMSYuNSrTkfJJn97S-_fQ7v_NqQaIM1us7fEka2HCMgx0Vqz2v6CXdZfBP0W7IQqxNH6FkOmlPAi1BeupXqTvBNg1h81Ibe6efcMQzCELFmH1rE91o1hRj0fuRkhpkuU_SaRWurCmkZTKnLiQRvIWm8T87IYkM8L45ydOMaPJ7I8ZA3nDKogW60fhjKuKpOPSDqiXoRZO0f4Itwp_qQ");

		const raw = JSON.stringify([
			{
				"macId": `${macAddress}`,
				"blessId": blessId
			}
		]);

		const requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: raw,
			redirect: "follow"
		};

		const response = await fetch(`${config.REACT_APP_BLESSING_LAMBDA_URL}factory_event`, requestOptions)
		const result = await response.json();
		return result;
	},
	deviceConfig: async (macAddress, blessId) => {
		console.log("in device config", macAddress, blessId)

		const myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append("Authorization", "Bearer " +window.localStorage.getItem('spaceio-key'));
		// myHeaders.append("Authorization", "Bearer eyJhbGciOiJSUzI1NiJ9.eyJ1dHAiOiJVIiwidHRwIjoiQUNDIiwic3ViIjoiMzEwOTc1IiwiYXVkIjoiaHR0cHM6Ly9iZXRhLWFkbWluLmFmcmVlc3BhY2UuY29tLz9yZWRpcmVjdF90bz1odHRwcyUzQSUyRiUyRmJldGEtYWRtaW4uYWZyZWVzcGFjZS5jb20lMkY5NDQlMkZjb250cm9scy5odG1sIiwiZXhwIjoxNzIxMzQwODE1LCJhaWQiOjEyLCJpYXQiOjE3MjA2OTI4MTV9.OisG-KZKJjP0WKx-QUYCtBFvE2H0I3oviavX_YZsYzjEKKoXINQaNlReAUv9HcPQmXjpcqgP46-aciMeZKux_f1HRSvJTHUvjLUIvMROg_fG1CVBDOBScvnB9RShjUuA3fmqMSYuNSrTkfJJn97S-_fQ7v_NqQaIM1us7fEka2HCMgx0Vqz2v6CXdZfBP0W7IQqxNH6FkOmlPAi1BeupXqTvBNg1h81Ibe6efcMQzCELFmH1rE91o1hRj0fuRkhpkuU_SaRWurCmkZTKnLiQRvIWm8T87IYkM8L45ydOMaPJ7I8ZA3nDKogW60fhjKuKpOPSDqiXoRZO0f4Itwp_qQ");

		const raw = JSON.stringify([
			{
				"macId": `${macAddress}`,
				"blessId": blessId
			}
		]);

		const requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: raw,
			redirect: "follow"
		};

		const response = await fetch(`${config.REACT_APP_BLESSING_LAMBDA_URL}device_config`, requestOptions)
		const result = await response.json();
		return result;
	},
	toggleDeviceConfigStatus: async (blessIds, status) => {
		const myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append("Authorization", "Bearer " +window.localStorage.getItem('spaceio-key'));

		const payload  = JSON.stringify({
			bless_ids: blessIds,
			status
		});

		const requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: payload,
			redirect: "follow"
		};
		const response = await fetch(`${config.REACT_APP_BLESSING_LAMBDA_URL}toggle_device_config_status`, requestOptions)
		const result = await response.json();
		return result;
	},
	fetchFactoryEvents: async (blessIds) => {
		const myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append("Authorization", "Bearer " +window.localStorage.getItem('spaceio-key'));

		const payload  = JSON.stringify({
			bless_ids: blessIds,
		});

		const requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: payload,
			redirect: "follow"
		};
		const response = await fetch(`${config.REACT_APP_BLESSING_LAMBDA_URL}fetch_factory_events`, requestOptions)
		const result = await response.json();
		return result;
	},
	storeFactoryEvents: async (data) => {
		const myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append("Authorization", "Bearer " +window.localStorage.getItem('spaceio-key'));

		const payload  = JSON.stringify(data);

		const requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: payload,
			redirect: "follow"
		};
		const response = await fetch(`${config.REACT_APP_BLESSING_LAMBDA_URL}store_factory_events`, requestOptions)
		const result = await response.json();
		return result;
	},
	event: async (data) => {
		const myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append("x-api-key", config.REACT_APP_FACTORY_KEY);

		const payload  = JSON.stringify(data);

		const requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: payload,
			redirect: "follow"
		};
		const response = await fetch(`${config.REACT_APP_BLESSING_LAMBDA_URL}event`, requestOptions)
		const result = await response.json();
		return result;
	},
	fetchProductType: async (productType) => {
		const myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append("Authorization", "Bearer " +window.localStorage.getItem('spaceio-key'));

		const requestOptions = {
			method: "GET",
			headers: myHeaders,
			redirect: "follow"
		};
		const response = await fetch(`${config.REACT_APP_BLESSING_LAMBDA_URL}fetch_product_type?product_type=${productType}`, requestOptions)
		const result = await response.json();
		return result;
	},
	items: async (hardwareDeviceId, data) => {
		const myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append("x-api-key", config.REACT_APP_FACTORY_KEY);

		const payload  = JSON.stringify(data);

		const requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: payload,
			redirect: "follow"
		};
		const response = await fetch(`${config.REACT_APP_BLESSING_LAMBDA_URL}items?item_mac=${hardwareDeviceId}`, requestOptions)
		const result = await response.json();
		return result;
	},
}
export default AuthService;