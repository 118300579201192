import React from "react";
import "./DotPulseLoader.module.css";

const DotPulseLoader = () => {
  return (
    <div className="loader">
      <div className="dotPulseLoader w-16">
        <div className="bounce1 bg-primary"></div>
        <div className="bounce2 bg-primary"></div>
        <div className="bounce3 bg-primary"></div>
      </div>
    </div>
  );
};

export default DotPulseLoader;
