import React from 'react';
import { useState, useRef } from "react";
import './fileDrop.css';
import excel from "../../assets/images/excel.svg";
import * as XLSX from 'xlsx';
import Button1 from "../../components/uploadButton/Button1";

function FileUploader({ onClick, setUploadedFileExtension }) {
    const [file, setFile] = useState(null);
    const fileInputRef = useRef(null);
    const [blessIdValues, setBlessedValues] = useState([]);

    const isFileValid = (file) => {
        const allowedExtensions = ['.csv', '.xls', '.xlsx', '.json'];
        const fileExtension = file.name.split('.').pop().toLowerCase();
        return allowedExtensions.includes(`.${fileExtension}`);
    };

    const handleFileUpload = (e) => {
        const uploadedFile = e.target.files[0];
        if (uploadedFile && isFileValid(uploadedFile)) {
            setFile(uploadedFile);
            readFileContents(uploadedFile);
        } else {
            setFile(null);
            alert("Invalid file format. Please upload a CSV or Excel or Json file.");
        }
    };

    const readFileContents = async (file) => {
        let type = '';
        const contents = await readFileAsBinaryString(file);
        if (file.name.endsWith('.csv')) {
            // Handle CSV file
            type = 'csv';
            parseCSVData(contents);
        } else if (file.name.endsWith('.xls') || file.name.endsWith('.xlsx')) {
            // Handle Excel file
            type = 'xlsx';
            parseExcelData(contents);
        } else if (file.name.endsWith('.json')) {
            // Handle JSON file
            type = 'json';
            parseJsonData(contents);
        }
        setUploadedFileExtension(type);
    };

    const readFileAsBinaryString = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => resolve(e.target.result);
            reader.onerror = (e) => reject(e);
            reader.readAsBinaryString(file);
        });
    };

    const findBlessIdIndex = (headers) => {

        const lowerCaseHeaders = headers.map(header => header.toLowerCase().replace(/\s/g, ''));
        const blessIdIndex = lowerCaseHeaders.findIndex(header => header.includes('blessid') || header.includes('bless_id'));
        const macIdIndex = lowerCaseHeaders.findIndex(header => header.includes('macid') || header.includes('mac_id'));

        if (blessIdIndex !== -1) {
            return blessIdIndex;
        } else if (macIdIndex !== -1) {
            return macIdIndex;
        } else {
            return -1; // Return -1 if neither 'bless id' nor 'mac id' is found
        }
    };

    const parseCSVData = (csvData) => {
        const lines = csvData.replace(/\r/g, '').split('\n');
        const headers = lines[0].split(',');
        const blessIdIndex = findBlessIdIndex(headers);


        if (blessIdIndex !== -1) {
            const blessedCSVValues = lines.slice(1).map((line) => line.split(',')[blessIdIndex]).filter(value => value !== undefined && value !== null && value.trim() !== '');  // Skip the header row, Get the 'bless id' value from each line, Filter out empty values          
            const undashedBlessedValues = blessedCSVValues.map(value => {
                if (value.includes('-')) {
                    return value.replace(/-/g, '');
                }
                return value;
            });

            undashedBlessedValues.map(value => {
                if (value.length === 10) {
                    setBlessedValues(undashedBlessedValues.map((value) => parseInt(value, 10)))
                }
                else if (value.length === 16) {
                    setBlessedValues(undashedBlessedValues)
                }
                else {
                    setBlessedValues(undashedBlessedValues)
                }
            });
        }
    };



    const parseExcelData = (excelData) => {
        const workbook = XLSX.read(excelData, { type: 'binary' });
        const firstSheetName = workbook.SheetNames[0];
        const sheetData = XLSX.utils.sheet_to_json(workbook.Sheets[firstSheetName]);
        if(sheetData.length > 0) {
            const headers = Object.keys(sheetData[0]);
            const blessIdIndex = findBlessIdIndex(headers);

            if (blessIdIndex !== -1) {
                const blessedExcelValues = sheetData.map(row => row[headers[blessIdIndex]]).filter(value => value !== undefined && value !== null && value !== '')
                const undashedBlessedValues = blessedExcelValues.map(value => {
                    if (typeof value === 'string' && value.includes('-')) {
                        return value.replace(/-/g, '');
                    }
                    return value;
                });
                undashedBlessedValues.forEach(value => {
                    if (value.length === 10) {
                        setBlessedValues(undashedBlessedValues.map((value) => parseInt(value, 10)))
                    }
                    else if (value.length === 16) {
                        setBlessedValues(undashedBlessedValues)
                    }

                    else {
                        setBlessedValues(undashedBlessedValues)
                    }
                });
            }
        }
    };

    const parseJsonData = (jsonData) => {
        setBlessedValues(JSON.parse(jsonData));
    }

    const handleDragOver = (e) => {
        e.preventDefault();
        const dragFile = e.dataTransfer.files[0];
        setFile(dragFile);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const droppedFile = e.dataTransfer.files[0];
        if (droppedFile && isFileValid(droppedFile)) {
            setFile(droppedFile);
        } else {
            setFile(null);
            alert("Invalid file format. Please upload a CSV or Excel file.");
        }
    };
    const handleLabelClick = () => {
        fileInputRef.current.click();
    };

    return (
        <div className='uploadFile'>

            <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileUpload}
                style={{ display: 'none' }} // Hide the input field
                data-testid="file-input"
            />

            <label
                htmlFor="fileInput"
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                style={{
                    border: '1px dashed #ccc',
                    borderRadius: '5px',
                    padding: '20px',
                    textAlign: 'center',
                    margin: '20px 0',
                    cursor: 'pointer',
                    height: '200px',
                    width: '500px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontStyle: 'Nunito',
                    fontWeight: '500',
                    fontSize: '13px',
                    color: '#444444',
                    lineHeight: '18px',
                }}
                onClick={handleLabelClick}
            >
                <img src={excel} alt="Excel Icon" ></img>
                {file ? (
                    <p data-testid="file-name">{file.name}</p>
                ) : (
                    <p><b>Click to upload </b>or drag and drop</p>
                )}
                <p>File Support: Excel/CSV/JSON</p>
                <p>For Debless or Migrate Out processes, please ensure the Excel file includes &quot;Bless ID&quot; or &quot;Mac ID&quot; as the column header.</p>
                <p>For the Migrate In process, please ensure to upload the downloaded JSON file.</p>
            </label>
            {file && (
                <div className="uploadingBtn">
                    <Button1
                        text={"Upload"}
                        style={{ marginBottom: "0", textColor: "white", borderRadius: "8px", margin: "1rem 0" }}
                        background={"linear-gradient(90deg, #F7707D 100%, #F29173 100%)"} color={"#ffffff"}
                        onClick={() => onClick(blessIdValues)}
                    // onClick={}
                    // tabIndex={overlay ? 0 : -1}
                    />
                </div>
            )}
        </div>
    );
}

export default FileUploader;
