import React from "react";

function InputField1({ logo, placeHolder, label, value, setValue, type, style, min, max }) {
    const inputStyle = {
        width: "100%",
        margin: "4px 0 6px 0",
        paddingLeft: "45px",
        borderRadius: "10px",
        height: "38px",
        float: "right",
        outline: "none",
        color: "#444444",
    }

    // const iconStyle = {
    //     float: "left",
    // }

    return (
        <>
            {label ? <><label style={{ marginTop: "16px", fontFamily: 'Nunito', fontStyle: "normal", fontWeight: "600", fontSize: "13px", lineHeight: "15px", color: "#444444", opacity: "0.8" }}>{label}</label><br /></> : null}

            <div className="inputWithIcon">
                {/* <img src={logo} alt="Email" style={{ color: "green", float: "left" }} /> */}

                <input className="inputField1" placeholder={placeHolder} autoComplete="off" style={inputStyle} required value={value} onChange={(e) => { setValue(e.target.value) }} type={type ? type : null} />
            </div>
        </>
    );
}

export default InputField1;