import React from "react";
import styles from "./Modal.module.css";
import closeIcon from "../../assets/images/closeIcon.svg";

const Modal = ({
  children,
  header = null,
  showCloseButton = false,
  actionIcon,
  showActionBar = false,
  size = "",
  dismissOnClick = true,
  isOpen,
  setIsOpen,
}) => {
  const modalSizeClass = () => {
    switch (size) {
      case "small":
        return styles.modalSm;
      case "large":
        return styles.modalLg;
      case "xlarge":
        return styles.modalXl;
      default:
        return "";
    }
  };
  return (
    isOpen && (
      <>
        <div
          className={styles.darkBG}
          onClick={() => dismissOnClick && setIsOpen(false)}
        />
        <div className={styles.centered}>
          <div className={`${styles.modal} ${modalSizeClass()}`}>
            {header ? (
              <div className={styles.modalHeader}>
                <div className={styles.heading}>{header}</div>
                {showCloseButton && (
                  <button
                    className={styles.closeBtn}
                    onClick={() => setIsOpen(false)}
                  >
                    <img src={closeIcon} alt="close" />
                  </button>
                )}
              </div>
            ) : (
              showCloseButton && (
                <button
                  className={styles.closeHoverBtn}
                  onClick={() => setIsOpen(false)}
                >
                  <img src={closeIcon} alt="close" />
                </button>
              )
            )}
            <div
              className={`${styles.modalContent} ${!header && styles.noHeader}`}
            >
              {actionIcon && (
                <img
                  className={styles.actionIcon}
                  src={actionIcon}
                  alt="action-icon"
                />
              )}
              <div className={styles.modalBody}>{children}</div>
            </div>
            {showActionBar && (
              <div className={styles.modalActions}>
                <div className={styles.actionsContainer}>
                  <button
                    className={styles.confirmBtn}
                    onClick={() => setIsOpen(false)}
                  >
                    Delete
                  </button>
                  <button
                    className={styles.cancelBtn}
                    onClick={() => setIsOpen(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    )
  );
};

export default Modal;
