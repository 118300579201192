export default {
    REACT_APP_PRODWS_AFREESPACE_URL: process.env.REACT_APP_PRODWS_AFREESPACE_URL || "https://accountws-ee-02.qa.afreespace.com",
    REACT_APP_DISCOVERY_API_URL: process.env.REACT_APP_DISCOVERY_API_URL || "https://discovery.afreespace.com",
    REACT_APP_DEFAULT_REFERRER_URL: process.env.REACT_APP_DEFAULT_REFERRER_URL || "https://account.afreespace.com",
    REACT_APP_LOGIN_API_URL: process.env.REACT_APP_LOGIN_API_URL || "https://login.dev.afreespace.com",
    REACT_APP_OLD_ELECTRIC_LAMBDA_URL: process.env.REACT_APP_OLD_ELECTRIC_LAMBDA_URL || "https://9rplmf5sc2.execute-api.eu-west-1.amazonaws.com/dev/",
    REACT_APP_NEW_ELECTRIC_LAMBDA_URL: process.env.REACT_APP_NEW_ELECTRIC_LAMBDA_URL || "https://rtmrfmygkb.execute-api.eu-west-1.amazonaws.com/dev/",
    REACT_APP_BLESSING_LAMBDA_URL: process.env.REACT_APP_BLESSING_LAMBDA_URL || "https://jglcn973h8.execute-api.eu-west-1.amazonaws.com/dev/",
    REACT_APP_DOMAIN_REFERRER_URL: process.env.REACT_APP_DOMAIN_REFERRER_URL || "https://factory-debless.dev.afreespace.com",
    REACT_APP_FACTORY_KEY: process.env.REACT_APP_FACTORY_KEY || "zO9yLEhtD89X4yiDezY7e7Kc4fi3K5YI2yCaSM02",
    REACT_APP_WIFI_SSID_NAME: process.env.REACT_APP_WIFI_SSID_NAME || "FreespaceOffice"
}